/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../../src/style/abstract/theme_colors';
@import '../../../../../src/style/abstract/theme_sizes';

@import '../../../../../src/style/abstract/font';
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/theme_mixin';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.BlogRelatedPosts {
    &-Title {
        text-align: center;
        text-transform: uppercase;

        @include after-mobile {
            margin-left: 0;
            text-align: center;
        }
    }

    &-Wrapper {
        margin: 0 10px;
    }

    &-PostsWrapper {
        display: grid;
        grid-gap: 11px 10px;
        grid-template-rows: repeat(auto-fill, 1fr);
        grid-template-columns: repeat(3, 1fr);

        @include mobile {
            display: block;
        }

        @include desktop {
            grid-gap: 15px 20px;
        }
    }
}
