/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../style/abstract/theme_colors';
@import '../../../../style/abstract/theme_sizes';

@import '../../../../style/abstract/font';
@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
@import '../../../../style/abstract/theme_mixin';

.EpayGateway {
    &-Wrapper {
        padding-top: var(--theme-spacing-64);
        padding-left: var(--theme-spacing-24);
        padding-right: var(--theme-spacing-24);

        @include before-desktop {
            padding-bottom: var(--theme-spacing-64);
        }
    }

    & .Loader {
        @include desktop {
            margin-top: var(--theme-spacing-64);
            min-height: 40rem;
        }
    }
}