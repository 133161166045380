/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../../../src/style/abstract/theme_colors';
@import '../../../../../../src/style/abstract/theme_sizes';

@import '../../../../../../src/style/abstract/font';
@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/theme_mixin';

.SubscriptionDetails {
    .Button {
        margin-right: 1rem;
    }

    &-Back {
        float: right;
        font-size: large;
        margin-top: 1.2rem;

        a {
            padding: 1.3rem 1.5rem 1rem 1.6rem;
            border: 1px solid var(--button-background);
            font-weight: 700;
            border-radius: 3rem;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        a:hover {
            text-decoration: none;
            color: #fff;
            background-color: #624627;
        }
    }
}