/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --input-border-color: var(--theme-color-10c);
    --input-border-color-focus: #{$black};
    --input-background-color: #fff;
    --input-padding: 13px 20px;
    --input-color: var(--primary-base-color);
}

input,
textarea,
select {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-color);
    padding: var(--input-padding);
    appearance: none;
    caret-color: var(--primary-base-color);

    &:focus {
        border: 1px solid var(--input-border-color-focus);
    }

    &:not(:first-of-type) {
        margin-top: 1.2rem;

        @include mobile {
            margin-top: 1.4rem;
        }
    }

    &:-webkit-autofill {
        box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
        background-color: var(--input-background-color) !important;
    }
}

textarea {
    width: 30rem;

    @include mobile {
        width: 35rem;
    }
}

[type='number'] {
    width: var(--input-type-number-width);
    font-weight: 700;
    padding-right: 0;
    -moz-appearance: textfield;

    &:focus {
        border: 1px solid var(--input-border-color);
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    ~ button {
        position: absolute;
        right: 0;
        padding: 0;
        margin: 0;
        width: 1.8rem;
        font-size: 1.8rem;
        line-height: 0;

        @include mobile {
            width: 2.1rem;
            font-size: 2.1rem;
        }

        &:first-of-type {
            @include plus-icon;
        }

        &:last-of-type {
            @include minus-icon;
            bottom: 0;
        }

        &:first-of-type,
        &:last-of-type {
            height: 50%;
        }

        &:disabled {
            opacity: .2;
            cursor: not-allowed;
        }
    }
}
