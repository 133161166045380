/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../style/abstract/theme_colors';
@import '../../style/abstract/theme_sizes';

@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme_mixin';

:root {
    @include before-desktop {
        --overlay-content-background: transparent;
    }
}

.MenuOverlay {
    &:not(.Overlay_isStatic) {
        @include before-desktop {
            top: 0;
            height: 100vh;
            padding-top: var(--header-total-height);
        }
    }
    
    &-SocialMediaWrapper {
        display: flex;
        justify-content: center;
        padding: var(--theme-spacing-24) 0;
    }
}