/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../hoeks-pwa/src/style/abstract/theme_colors';
@import '../../../../hoeks-pwa/src/style/abstract/theme_sizes';

@import '../../../../hoeks-pwa/src/style/abstract/font';
@import '../../../../hoeks-pwa/src/style/abstract/variables';
@import '../../../../hoeks-pwa/src/style/abstract/media';
@import '../../../../hoeks-pwa/src/style/abstract/button';
@import '../../../../hoeks-pwa/src/style/abstract/loader';
@import '../../../../hoeks-pwa/src/style/abstract/icons';
@import '../../../../hoeks-pwa/src/style/abstract/parts';
@import '../../../../hoeks-pwa/src/style/abstract/theme_mixin';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */



.Menu {
    &-ItemList:not(.Menu-ItemList_type_main) {
        @include mobile {
            margin-top: 70px;
        }
    }
}
