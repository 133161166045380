/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../../../src/style/abstract/theme_colors';
@import '../../../../../../src/style/abstract/theme_sizes';

@import '../../../../../../src/style/abstract/font';
@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/theme_mixin';

.SearchItem {
    &-Wrapper {
        grid-template-columns: 5.6rem auto;

        .Image {
            padding-bottom: 125%;
        }
    }

    &-Title {
        margin: 0;
        margin-bottom: .6rem;
        text-transform: uppercase;
        font-size: 1.3rem;
        max-width: 100%;
        white-space: normal;
    }

    &-Price {
        margin: 0 !important;
        font-weight: 400;
    }
}
