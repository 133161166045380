/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../../src/style/abstract/theme_colors';
@import '../../../../../src/style/abstract/theme_sizes';

@import '../../../../../src/style/abstract/font';
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/theme_mixin';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --posts-details-title-size: 28px;
}

.PostsDetails {
    &-Wrapper {
        padding-bottom: 30px;

        @include mobile {
            padding-top: 5px;
        }
    }

    &-ColumnWrapper {
        display: flex;

        @include mobile {
            flex-wrap: wrap;
        }
    }

    &-Description {
        @include mobile {
            order: 1;
        }

        @include after-mobile {
            flex-basis: 70%;
            padding-left: 40px;
        }

        .Image_ratio_square {
            height: auto;
            padding-bottom: 0;
        }

        .Image-Content {
            position: relative;
        }
    }

    &-Sidebar {
        flex-basis: 30%;
        margin-top: 2rem;
        @include mobile {
            display: none;
        }
    }

    &-Title {
        font-size: var(--posts-details-title-size);
        text-align: center;
        text-transform: uppercase;
    }

    &-Date,
    &-Author {
        display: block;
        text-align: right;
    }

    &-ContentPlaceholder {
        &::after {
            content: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            word-break: break-all;
        }
    }

    &-DatePlaceholder {
        &::after {
            content: 'aaaa/aa/aa';
        }
    }

    &-Iframe {
        margin: 0 auto 1.2rem;
        padding: 56.25% 0 0 0;
        position: relative;

        iframe {
            height: 100% !important;
            left: 0;
            position: absolute;
            top: 0;
            width: 100% !important;
        }
    }
}
