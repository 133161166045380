/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../style/abstract/theme_colors';
@import '../../../style/abstract/theme_sizes';

@import '../../../style/abstract/font';
@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/theme_mixin';

.BlogRecentPosts {
    $block: &;
    
    &-PostTitle {
        @include font-heading4;
        margin-top: var(--theme-spacing-16);

        @include desktop {
            max-width: 325px;
        }
    }

    &-Details {
        & a {
            &:hover {
                text-decoration: none;
            }
        }
    }

    &-PostCard {
        padding: 0;
        flex-direction: column;
        border: none !important;

        &:nth-child(1n+3) {
            @include before-desktop {
                display: none;
            }
        }

        & .BlogRecentPosts-PostTitle {
            font-size: 2.4rem;
            line-height: 3.1rem;
        }

        & .BlogRecentPosts-ImageWrapper {
            & .Image:not(.Logo) {
                height: 100%;
                width: 100%;

                object-fit: cover;
                height: 0;
                padding-bottom: 450px;
                display: block;
        
                    & .Image-Image {
                        width: 100% !important;
                        height: 100% !important;
                        position: absolute;
                        object-fit: cover;

                        transform: scale(1);
                        transition: all ease .3s;

                        @at-root .BlogRecentPosts-PostCard:hover & {
                            transform: scale(1.05);
                        }
                    }
            }
    
            width: 100%;
        }

        &:hover {
            & #{$block}-PostTitle {
                color: var(--primary-base-color);
            }
        }
    }

    &-Date {
        position: relative;
        border: unset;
    }

    @at-root .BlogRecentPosts-Wrapper & {
        &-Wrapper {
            height: 100%;
            background: #fff;
            padding: 2.4rem;
        }
    }

    &-Wrapper {
        flex-basis: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-gap: var(--theme-spacing-20);

        @include tablet {
            grid-template-columns: 1fr 1fr;
        }

        @include phone {
            grid-template-columns: 1fr;
        }
    }

    &-Header {
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-gap: var(--theme-spacing-20);
        padding-bottom: var(--theme-spacing-32);

        @include tablet {
            grid-template-columns: 2fr 1fr;
        }

        @include phone {
            grid-template-columns: 1fr;

            border-top: 1px solid var(--theme-color-10e);
            padding-top: var(--theme-spacing-32);
        }
    }

    &-Headding {
        @include font-heading2;
        align-self: center;
    }

    &-Link {
        align-self: center;
        letter-spacing: 0.1rem;
        text-transform: uppercase;

        @include phone {
            display: none;
        }

        & a {
            color: var(--black);

            &:hover {
                text-decoration: none;
                color: var(--primary-base-color);
            }
        }
    }

    &-LinkArrow {
        background: url('icons/TextButtonArrow-icon.svg') no-repeat center left;
        padding: 10px;
        margin-left: 10px;
    }
}
