.CMSFooterIcons {
    display: flex;
    align-items: center;

    & .Image {
        padding: 0 var(--theme-spacing-8);
        display: block;
        height: auto;
        overflow: auto;

        & > .Image-Image {
            position: static;
        }
    }
}