/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../style/abstract/theme_colors';
@import '../../style/abstract/theme_sizes';

@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme_mixin';

.ProductCard {
    $block: &;
    margin-bottom: var(--theme-spacing-48);
    flex-direction: column;
    // justify-content: stretch;

    &:last-child {
        @include desktop {
            margin-bottom: var(--theme-spacing-48);
        }
    }

    &-PriceRunner {
        width: 120px;
        max-width: 50%;
        position: absolute;
        bottom: 0;
        display: flex;
        margin-left: 10px;
        margin-bottom: 10px;
    }

    .AddToCart {
        margin-top: auto;
        flex-grow: 0 !important;
        @include mobile {
            font-size: 1rem;
            padding-left: 10px;
            padding-right: 10px;
            min-width: 100%;
        }
    }

    .ProductViewMore {
        margin-top: auto;
        .Button {
            @include mobile {
                font-size: 1rem;
                padding-left: 10px;
                padding-right: 10px;
                min-width: 100%;
            }
        }
    }

    &-ConfigurableOptions {
        &:empty {
            min-height: 0;
        }
    }

    &-FigureReview {
        position: relative;
        // background-color: var(--theme-color-7);
        padding: var(--theme-spacing-48) 0;
        border: 1px solid #e2e2e2;
    }

    &-Figure {
        .Image {
            // background-color: var(--theme-color-7);
            .Image-Image {
                // mix-blend-mode: multiply;
            }
        }
    }

    &-ProductActions {
        top: var(--theme-spacing-20);
        right: var(--theme-spacing-20);

        @include phone {
            right: var(--theme-spacing-8);
        }
    }

    &-Badges {
        position: absolute;
        top: var(--theme-spacing-20);
        left: 0;
    }

    &-Badge {
        padding: var(--theme-spacing-8) var(--theme-spacing-16);
        border-top-right-radius: 999px;
        border-bottom-right-radius: 999px;
        margin-bottom: var(--theme-spacing-8);

        @include font-product2;

        @include before-desktop {
            font-size: 10px;
            letter-spacing: 0.05rem;
        }
        
        color: var(--white);
        background-color: var(--theme-color-1);

        &.SpecialPrice {
            background-color: var(--theme-color-badge-2);
        }

        &_type_badge2 {
            background-color: var(--theme-color-badge-2);
        }

        &.BadgeRoastProfile {
            background-color: var(--theme-color-badge-3);
        }

        &_type_badge4 {
            background-color: var(--theme-color-badge-4);
            color: var(--black);
        }
        &.NoStock {
            background-color: var(--theme-color-badge-5);
            color: var(--white);
        }
        &.BadgeNewProduct {
            background-color: #e0d8c3;
            color: var(--black);
        }
        &.RestSalg {
            background-color: #f3d46a;
            color: var(--black);
        }
        &.BadgeOkologisk {
            background-color: var(--theme-color-badge-2);
            color: var(--white);
        }
        &.BadgeOnOrderGoods {
            background-color: var(--theme-color-3);
            color: var(--white);
        }
    }

    &-Content {
        padding: var(--theme-spacing-20) 0 0;
    }

    &-Type {
        @include font-product2;

        margin: 0 0 var(--theme-spacing-8);
    }

    &-Name {
        overflow: hidden;
        white-space: normal;
        text-overflow: unset;

        // @supports (-webkit-line-clamp: 2) {
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        //     white-space: initial;
        //     display: -webkit-box;
        //     -webkit-line-clamp: 2;
        //     -webkit-box-orient: vertical;
        // }
    }

    #{$block} &-Name {
        margin: 0 0 var(--theme-spacing-8);

        @include font-product1;
    }

    &-PriceBadge {
        display: none;
    }

    &-PriceWrapper {
        display: block;
        @include font-product-price;

        & p {
            font-size: inherit;
        }
    }

    &-Price {
        font-size: inherit;
        display: contents;
        text-align: center;
    }

    &-FromPrice {
        display: inline;
        margin-right: 5px;
        text-transform: none;
    }

    &-ShortDescription {
        margin-bottom: var(--theme-spacing-8);
        font-size: 1.5rem;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 42px;

        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        #{$block} & p,
        #{$block} & span {
            margin-bottom: 0;
            line-height: 1.3;
            font-size: 1.5rem !important;
        }
    }

    &-Sku {
        margin-bottom: var(--theme-spacing-8);
        font-size: 1.5rem;
    }

    &-WishListButton {
        @include phone {
            width: 29px;
            height: 29px;
        }
    }

    & .ProductWishlistButton {
        --loader-scale: 0.6;

        &-Button {
            &_isInWishlist {
                & .ProductWishlistButton-Heart {
                    background-color: var(--white);
                    
                    &::before,
                    &::after {
                        background-color: var(--white);
                    }
                }
            }
        }
    }
}