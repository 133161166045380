/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../../../src/style/abstract/theme_colors';
@import '../../../../../../src/style/abstract/theme_sizes';

@import '../../../../../../src/style/abstract/font';
@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/theme_mixin';

.Clerk {
    margin-top: 4.8rem;
    &-Title {
        font-size: 3.2rem;
        margin-top: 0;
        text-transform: uppercase;
        letter-spacing: -0.02em;
        font-weight: 600;
        padding-bottom: 2rem;
        text-align: center;
    }

    &-Wrapper {
        .CategoryPage & {
            padding: 0;

            @include desktop {
                overflow: hidden;
            }

            @include mobile {
                padding-left: 1rem;
                padding-right: 1rem;
            }

        }
    }

    &-Slider {
        .CategoryPage & {
            margin-left: -.6rem;
            margin-right: -.6rem;
        }
        .slick-slider {
            .ProductCard {
                padding: 0 20px;
                @include desktop {
                    padding: 0 50px;
                }
            }

            .slick-arrow {
                height: 25px;
                width: 25px;
                &.slick-next {
                    right: .6rem;
                }

                &.slick-prev {
                    left: .6rem;
                }
            }
        }

        .CategoryPage & {
            .slick-slider {
                @include desktop {
                    padding-left: 0;
                    padding-right: 0;
                }
                
                .ProductCard {
                    @include desktop {
                        padding: 0 20px;
                    }
                    @include desktop {
                        padding: 0 .6rem;
                    }
                }
            }
        }

    }
}