/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../style/abstract/theme_colors';
@import '../../../style/abstract/theme_sizes';

@import '../../../style/abstract/font';
@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/theme_mixin';

:root {
    --max-blog-width: 800px;
}

.PostsDetails {
    --total-header-height: calc(var(--header-menu-height) + var(--header-height) + 1px);
    overflow-x: hidden;

    &-Wrapper {
        max-width: unset;
        padding-bottom: 0;
    }

    &-Header {
        section {
            padding-bottom: var(--theme-spacing-32);
        }

        .Image {
            padding: 0;
            height: 54rem;
            width: 100%;

            img {
                object-fit: cover;
            }
        }
    }

    &-HeaderWrapper {
        max-width: var(--max-blog-width);
        padding: 0;
    }

    &-HeaderContent {
        color: var(--cms-light-font-color);

        @include mobile {
            padding: 0 2.4rem;
        }
    }

    &-Title {
        @include font-heading1;
        padding-top: var(--theme-spacing-96);
        text-align: center;
        padding-bottom: var(--theme-spacing-48);
        text-transform: unset;
        //color: white;
        //text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);

        @include mobile {
            font-size: 2.4rem;
        }
    }

    &-HeaderMeta {
        padding-left: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: var(--theme-spacing-32);
    }

    &-HeaderBackLink {
        position: absolute;
        top: calc(var(--theme-spacing-48) * -1);
    }

    &-HeaderBackLinkText {
        font-size: var(--theme-font-size);
        font-weight: var(--theme-font-weight-bold);
        color: var(--white);
        text-transform: uppercase;
        letter-spacing: .06rem;
        line-height: 30px;
        vertical-align: middle;
        display: inline-block;
        text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);

        &::before {
            content: '';
            background: url(icons/back.svg);
            background-size: cover;
            height: 30px;
            width: 30px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            top: -1px;
            position: relative;
            filter: drop-shadow( 0px 1px 3px rgba(0, 0, 0, .7));
        }
    }

    &-Author,
    &-Date {
        text-align: left;
        font-weight: 500;
        padding-right: 10px;
    }

    &-HeaderShare {
        display: flex;
        justify-content: center;
    }

    &-ShareText,
    &-ShareSeparator,
    &-ShareLink {
        @include font-paragraph;
        font-weight: var(--theme-font-weight-semibold);
    }

    &-ShareText,
    &-ShareSeparator {
        margin-right: var(--theme-spacing-8);
    }

    &-ShareLink {
        margin-right: var(--theme-spacing-16);
        cursor: pointer;
        
        &:hover {
            text-decoration: underline;
            text-underline-offset: var(--theme-link-underline-offset);
        }

        &_hasCopied {
            color: var(--primary-base-color);
        }
    }

    &-ColumnWrapper {
        grid-gap: var(--theme-spacing-128);
        padding-bottom: var(--theme-spacing-96);
        flex-direction: column;

        @include tablet {
            gap: var(--theme-spacing-32);
            padding-bottom: var(--theme-spacing-32);
        }

        @include phone {
            gap: var(--theme-spacing-16);
            padding-bottom: var(--theme-spacing-32);
        }
    }

    &-Grid {
        padding: 0;
        grid-template-columns: 1fr;
        grid-gap: var(--blog-grid-gap);
    }

    &-Description {
        flex-basis: 100%;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto;

        > .Image {
            margin-top: 1.2rem;
        }
    }

    &-Sidebar {
        flex-basis: 100%;
        width: 100%;

        @include before-desktop {
            display: initial;
            order: 2;
        }

        & .BlogRecentPosts-OuterWrapper {
            max-width: var(--content-wrapper-width);
            margin: auto;
            padding: 0 var(--theme-spacing-24);
        }
    }

    &-Content {
        margin-top: 2.4rem;

        .Image {
            margin: 0 auto;
            display: block;
        }

        .Image-Image {
            position: relative;
            object-fit: cover;
            height: auto !important;
            display: block;
        }
    }
    
    .blog-feature{
        background: white; 
        margin-bottom: -3rem;

        @include desktop {
            width: 1000px; 
            transform: translate(-100px, 20px);
            margin-bottom: -1rem;
        }
        .blog-feature-txt {
            padding: 2rem;

            @include desktop {
                width: 800px;
                margin: auto;
                padding: 2rem 0 0;
            }
        }
        .blog-feature-column2 {

            @include desktop {
                width: 50%;
                display: inline-grid;
            }
        }
        h3 {
            margin-top:1rem;
            font-size: 1.6rem;
        }
        .blog-feature-img-row {
            display: inline-flex;

            .feature-img {
                margin:0 0.3%;
                height: auto;
                padding-bottom: 0 !important;
            }
        }
    }
    .enlarge-img {
        width: auto;
        
        @include desktop {
            width: calc(100% + 200px);
            margin-left: -100px;
        }
    }
    .blog-qoute {
        font-size: 2rem;
        text-align: center;
        padding-top: 4rem;
    }
    .pad10left {
        @include desktop {padding-left: 20px;}
        
    }
    .pad10right {
        @include desktop {padding-right: 20px;}
    }
}