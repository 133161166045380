/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../style/abstract/theme_colors';
@import '../../style/abstract/theme_sizes';

@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme_mixin';

.CategoryPagination {
    &-ListItem {
        // height: var(--theme-spacing-48);
        // width: var(--theme-spacing-48);
        min-width: var(--theme-spacing-48);
        min-height: var(--theme-spacing-48);
    }

    &Link {
        min-width: var(--theme-spacing-48);
        min-height: var(--theme-spacing-48);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-Icon {
        background-color: var(--theme-color-7);
        border-radius: 999px;
        width: var(--theme-spacing-48);
        height: var(--theme-spacing-48);
        
        &:not(.CategoryPagination-Icon_isNext) {
            margin-right: 16px;
        }

        &_isNext {
            margin-left: 16px;
        }

        &::before {
            content: none;
        }

        &::after {
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}