.isForgotPasswordPageB2B {
    &.isLoginPageB2B {
        .ForgotPassword-InnerWrapper {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            color: var(--white);
            button {
                background-color: var(--white);
                color: var(--theme-color-1);
            }
            input {
                background-color: var(--white);
            }
            .MyAccountOverlay-Buttons {
                @include mobile {
                    margin-top: 2.4rem;
                }
            }
        }
        .ForgotPassword-SignInWrapper {
            margin-top: 0;
        }
    }
}