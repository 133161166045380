/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../style/abstract/theme_colors';
@import '../../../../style/abstract/theme_sizes';

@import '../../../../style/abstract/font';
@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
@import '../../../../style/abstract/theme_mixin';

:root {
    --product-card-wishlist-button-color: none;
    --section-width: 1225px;
}

.CategoryPage .Clerk-Wrapper{
    overflow: visible !important;
}

.Clerk{
    margin-top: 0;

    &-Title{
        @include font-heading2;
        text-align: left;
        padding-bottom: var(--theme-spacing-24);
    }
  
  // &-Slider{
  //   max-width: 1245px;
  //   margin: 0 auto;
  //   margin-left: 0 !important;
  // }

    &-Wrapper {
        max-width: var(--content-wrapper-width);
        padding: 0 var(--theme-spacing-24);
        margin-top: var(--theme-spacing-96);
        margin-bottom: var(--theme-spacing-96);

        .isHomePage &,
        .isCmsPage & {
            padding: 0; 
            @include before-desktop {
                margin-top: var(--theme-spacing-24);
                margin-bottom: var(--theme-spacing-24);
            }
        }

        .isProductPage & {
            margin-top: 0;
            padding-top: 8rem;
            @include mobile {
                padding-top: 4rem;
            }
        }
        .ProductPage & {
            margin-top: var(--theme-spacing-96);
            padding-top: 0;
            @include mobile {
                padding-top: 0;
            }
        }
    }

    &-Slider {
        .slick-slider  {

            .slick-track {
                display: flex !important;
            }

            .slick-slide {
                height: inherit!important;
                & > div {
                    height: 100%;
                    & > div {
                        height: 100%;
                        & > li {
                            height: 100%;
                        }
                    }
                }
            }

            .slick-list {
                margin: 0 -1rem;
                @include desktop {
                    pointer-events: auto;
                }
            }

            .slick-dots {
                bottom: -25px;

                .isCmsPage &,
                .isHomePage & {
                    bottom: 0;
                }

                .isHomePage & {
                    bottom: -30px;
                }

                li {
                    button {
                        background-color: var(--expandable-content-divider-color);
                        margin-right: 1rem;
                    }
                    &.slick-active {
                        button {
                            background-color: #000;
                        }
                    }
                }

            }

            .slick-arrow {
                width: 50px;
                height: 50px;
                background-color: #fff;
                border-radius: 50%;
                top: 50%;
                transform: translateY(-50%)!important;
                z-index: 10;
                cursor: pointer;
                border: 1px solid var(--theme-color-10e);

                &:before {
                    color: #000;
                    content: '\203A';
                    line-height: normal;
                    font-family: var(--theme-font-family-primary);
                    display: block;
                    font-size: 30px;
                    position: relative;
                }
                &.slick-prev {
                    left: 5px;
                    right: auto;
                    &:before {
                       transform: rotate(180deg)!important;
                       top: 4px;
                    }
                }

                &.slick-next {
                    transform: none;
                    right: 5px;
                    left: auto;
                    &:before {
                        bottom: 2px;
                    }
                }

                &.slick-disabled {
                    display: none!important;
                }

            }

            .ProductCard {
                padding: 0;
                margin: 0 1rem;
                &-Figure {
                    .Image {
                        // background-color: var(--theme-color-7);
                        .Image-Image {
                            // mix-blend-mode: multiply;
                        }
                    }
                }
            }
        }
    }
}


// .slick-track .ProductCard {
//   // min-height: 458px;
//   padding: 0 !important;
//   margin-left: 25px;
//   margin-right: 0px;

//   &-Content {
//     background: #f1f1f1;
//   }

//   &-Name {
//     font-weight: bold;
//     white-space: normal;
//   }
// }

// .Clerk-Slider .slick-slider .slick-arrow.slick-next,.Clerk-Slider .slick-slider .slick-arrow.slick-prev{
//   z-index: 5;

//   @include mobile{
//     display: none !important;
//   }

//   &::before{
//     color: $ClerkBg;
//     font-size: 35px;
    
//   }
// }

// .Clerk-Slider .slick-slider .slick-arrow.slick-prev{
//   @media only screen and (min-width: 1300px) {
//     left: -4.3rem;
//   }

//   @media only screen and (max-width: 1299px) {
//     left: 1rem;
//   }
// }

// .Clerk-Slider .slick-slider .slick-arrow.slick-next{
//   @media only screen and (min-width: 1300px) {
//     right: -1.6rem;
//   }

//   @media only screen and (max-width: 1299px) {
//     right: 4.6rem;
//   }
// }