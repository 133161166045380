.CMSMenuOverlaySocialMedia {
    $block: &;
    $cmsMenuOverlaySocialMedia-size: ($theme-spacing-32);
    $cmsMenuOverlaySocialMedia-size-half: ($theme-spacing-32 / 2);
    
    display: flex;
    margin-left: calc(var(--theme-spacing-8) * -1);
    margin-right: calc(var(--theme-spacing-8) * -1);

    &-Link {
        display: block;
        position: relative;
        padding: #{$cmsMenuOverlaySocialMedia-size-half};
        border-radius: #{$cmsMenuOverlaySocialMedia-size};
        margin-left: var(--theme-spacing-8);
        margin-right: var(--theme-spacing-8);
		background-color: var(--white);
		transition: background-color .25s ease-out;

		&:hover {
			@include desktop {
				transition: background-color .25s ease-out;
				background-color: var(--theme-color-10d);
			}
		}

        &_type_instagram {
            & #{$block}-Icon {
                background: url('./images/Instagram.svg') no-repeat center;
                transform: translate(-50%, -50%) scale(0.8);
            }
        }

        &_type_facebook {
            & #{$block}-Icon {
                background: url('./images/Facebook.svg') no-repeat center;
                transform: translate(-50%, -50%) scale(0.8);
            }
        }

        &_type_linkedIn {
            & #{$block}-Icon {
                background: url('./images/LinkedIn.svg') no-repeat center;
                transform: translate(-50%, -50%) scale(0.8);
            }
        }

        &_type_youtube {
            & #{$block}-Icon {
                background: url('./images/Youtube.svg') no-repeat center;
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }

    &-Icon {
        position: absolute;
        display: flex;
        justify-content: center;
        width: #{$cmsMenuOverlaySocialMedia-size-half};
        height: #{$cmsMenuOverlaySocialMedia-size-half};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: var(--theme-color-1-filter);
    }
}