/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../style/abstract/theme_colors';
@import '../../../style/abstract/theme_sizes';

@import '../../../style/abstract/font';
@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/theme_mixin';

:root {
    --blog-grid-gap: var(--theme-spacing-20);
    --total-header-height: calc(var(--header-menu-height) + var(--header-height) + 1px);
}

.PostsListing {
    $block: &;

    background-color: var(--white);

    @include desktop {
        //margin-top: calc(var(--header-total-height) * -1);
    }

    .ContentWrapper {
        padding-top: 50px;
        padding-left: var(--theme-spacing-24);
        padding-right: var(--theme-spacing-24);
    }

    &-Header {
        margin-top: -41px;
        height: 70vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: calc(var(--total-header-height) / 2);

        h1 {
            font-size: 6rem;
            color: var(--cms-light-font-color);
            text-align: center;

            @include mobile {
                font-size: 4rem;
            }
        }

        p {
            font-size: 4rem;
            color: var(--cms-light-font-color);

            @include mobile {
                font-size: 2.4rem;
            }
        }
    }

    &-ColumnWrapper {
        grid-gap: 10px;
        padding-top: 50px;
        padding-bottom: 100px;
        display: grid;
        grid-template-columns: 1fr;
    }

    &-Grid {
        padding: 0;
        grid-template-columns: 1fr;
        grid-auto-rows: 500px;
        grid-gap: var(--blog-grid-gap);
        flex: auto;
        order: unset;

        @include tablet {
            grid-template-columns: repeat(2, 1fr);
            
        }

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &-LoadMoreWrapper {
        grid-column: 1 / -1;
        order: unset;
    }

    & #{$block}-LoadMoreButton {
        border-radius: var(--button-radius);
    }
}
