.CMSCategoryGrid {
    display: grid;
    grid-template-columns: auto;
    gap: var(--theme-spacing-20);
    position: relative;

    @include tablet {
        grid-template-columns: repeat(2, 1fr);
    }

    @include desktop {
        grid-template-columns: repeat(3, 1fr);
    }

    &-Card {
        background-color: var(--theme-color-1);
        height: 35rem;
    }

    &-Link {
        display: block;
        width: 100%;
        height: 100%;
    }

    &-Image {
        &.Image:not(.Logo) {
            width: 100%;
            height: 100%;

            & .Image-Image {
                width: 100% !important;
                height: 100% !important;
                object-fit: cover;
            }
        }

        padding-bottom: unset;
        height: 100%;
        width: 100%;

        & .Image-Image {
            object-fit: cover;
            transform: scale(1);
            transition: all ease .3s;

            @at-root .CMSCategoryGrid-Card:hover & {
                transform: scale(1.05);
            }
        }
    }

    &-Text {
        display: block;
        position: absolute;
        top: var(--theme-spacing-32);
        left: var(--theme-spacing-32);

        @include font-heading3;
        letter-spacing: 0.1rem;
        color: var(--white);
    }
}