/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../style/abstract/theme_colors';
@import '../../../style/abstract/theme_sizes';

@import '../../../style/abstract/font';
@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/theme_mixin';

.BlogCategories {
    $block: &;

    &-Wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 0 -5px;
        margin-bottom: -10px;
        flex-wrap: wrap;

        // @include mobile {
        //     justify-content: flex-start;
        // }
    }

    &-Category {
        border: none;
        padding: 0 5px;
        margin-bottom: 10px;

        a {
            text-decoration: none !important;

            &:hover {
                text-decoration: none;
            }
        }

        &::after {
            display: none;
        }

        &:last-child {
            border: none;
        }

        &_isActive {
            & #{$block}-CategoryTitle {
                text-decoration: none;
                background-color: var(--primary-base-color);
                color: #fff;
            }
        }
    }

    &-CategoryTitle {
        padding: 1.2rem 2.4rem;
        background: transparent;
        margin: 0;
        transition: all ease-out .25s;

        border-radius: 999px;
        border: 1px solid var(--theme-color-10e);
        font-size: var(--theme-font-size);
        font-weight: var(--theme-font-size);
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: var(--black);

        &:hover {
            text-decoration: none;
            background-color: var(--primary-base-color);
            color: var(--white);
        }
    }
}
