/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../style/abstract/theme_colors';
@import '../../style/abstract/theme_sizes';

@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme_mixin';

.NavigationTabs {
    &-Nav {
        height: 6rem;
        background-color: var(--theme-color-7);
        padding: 0 var(--theme-spacing-24);
        justify-content: flex-end;
        width: 100%;
    }

    body &-Button {
        margin-right: var(--theme-spacing-16);
    }

    body &-LogoBtn {
        justify-self: flex-start;
        flex-grow: 1;
    }

    body &-LogoWrapper {
        height: 2.4rem;
        width: fit-content;

        & .Logo {
            position: relative;
            left: 0;
        }

        & .Image-Image {
            position: relative;
            left: 0;
        }
    }
}