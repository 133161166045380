/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../style/abstract/theme_colors';
@import '../../../../style/abstract/theme_sizes';

@import '../../../../style/abstract/font';
@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
@import '../../../../style/abstract/theme_mixin';

.ClerkSearch {
    pointer-events: all;

    @include desktop {
        position: initial;
        left: initial;
        top: initial;
        transform: initial;
        width: initial;
    }

    &_mobileActive {
        display: flex;
        justify-content: center;
        margin-top: 0;
        margin-bottom: var(--theme-spacing-24);
    }

    &-Input {
        height: var(--theme-spacing-48);
        border-radius: var(--theme-spacing-48);
        background-color: var(--white);
        padding: var(--theme-spacing-8) var(--theme-spacing-16) var(--theme-spacing-8) var(--theme-spacing-48);
        border-width: 0;

        @include font-menu-search;

        @include desktop {
            min-width: 20.0rem;
            width: 20.0rem;
        }

        @include tablet {
            min-width: 40.0rem;
        }

        &:hover, &:focus, &:active {
            border-width: 0;
        }
        
        &::placeholder {
            color: var(--theme-color-9);
        }

        &-Icon {
            width: 2rem;
            height: 2rem;
            left: var(--theme-spacing-16);
            right: auto;
            background-image: url('image/Search-icon.svg');
            background-repeat: no-repeat;
            pointer-events: none;
        }
    }

    &-Results {
        top: 100%;
        transform: none;
        display: grid;
        grid-template-columns: 12fr 8fr;
        width: 90vw;
        max-width: 1000px;
        border-radius: 5px;
        left: auto;
        right: 125px;

        @include mobile {
            box-shadow: none;
            border: none;
            top: calc(100% + 10px);
            width: 100%;
            left: 0;
            transform: none;
            max-height: 80vh;
            overflow-x: auto;
            overflow-y: scroll;
        }

        @include phone {
            grid-template-columns: 1fr;
            border-radius: 0;
        }

        .SearchItem {
            margin: 0;
            border-bottom: 1px solid rgba(0, 0, 0, .05);

            .Image {
                width: 50px!important;
                height: 50px!important;
                padding-bottom: 0;
            }

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border: none;
                padding-bottom: 0;
            }

            &-Wrapper {
                min-height: 50px;
            }

            &-Title {
                font-family: var(--theme-font-family-primary);
                font-weight: 600;
                color: var(--black);
                letter-spacing: 0.15rem;
                font-size: 1.4rem;
            }

            &-Price {
                font-family: var(--theme-font-family-primary);
                font-weight: 600;
                color: var(--black);
                letter-spacing: 0.15rem;
                font-size: 1.1rem;
            }

        }
    }

    &-ResultHeading {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 1.4rem;
        margin-bottom: 1.8rem;
    }

    &-ResultColumn {
        padding: 2.4rem;

        &_type {
            &_leftColumn {
                border-bottom: 1px solid rgba(0, 0, 0, .05);
                border-right: 1px solid rgba(0, 0, 0, .05);

                @include phone {
                    border-right: none;
                }
            }

            &_rightColumn {
                border-bottom: 1px solid rgba(0, 0, 0, .05);
                padding: 0;
            }

            &_bottom {
                grid-column: 1 / 3;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                display: none;

                @include phone {
                    grid-column: 1 / 2;
                }
            }
        }
    }

    &-ResultContainer {
        &_type {
            &_suggestions {
                padding: 2.4rem;
                border-bottom: 1px solid rgba(0, 0, 0, .05);
            }

            &_categories {
                padding: 2.4rem;
            }
        }
    }

    &-SuggestionItem,
    &-CategoryItem {
        padding: 0;

        &::before {
            display: none;
        }
    }

    &-SuggestionItem {
        &:last-child {
            margin-bottom: 0;
        }
    }

}

.isSearchPage .CategoryProductList-Page {
    // ClerkSearch overrides how many productcards are displayed per row, but it overrides it for all device sizes :(
    // Need to override it back to default.
    grid-template-columns: repeat(4, 1fr);
    
    @include before-desktop {
        grid-template-columns: repeat(2, 1fr);
    }
}