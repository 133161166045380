/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../style/abstract/theme_colors';
@import '../../style/abstract/theme_sizes';

@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme_mixin';

.ProductWishlistButton { 
    $block: &;

    display: flex;
    align-items: flex-end;

    @include tablet {
        margin-top: 0;
    }

    &-Button {
        width: 48px;
        height: 48px;

        @include phone {
            width: 51px;
            height: 51px;
        }

        @include tablet {
            width: 63px;
            height: 63px;
        }
        
        &.Button_isHollow {
            &:not([disabled]) {
                & #{$block}-Heart {
                    background-color: var(--theme-color-1);

                    @include desktop {
                        background-color: var(--theme-color-1);
                    }

                    &::before,
                    &::after {
                        background-color: var(--theme-color-1);

                        @include desktop {
                            background-color: var(--theme-color-1);
                        }
                    }
                }

                &:focus {
                    background-color: transparent;
                    border-color: var(--theme-color-1);

                    @include desktop {
                        background-color: transparent;
                        border-color: var(--theme-color-1);
                    }

                    & #{$block}-Heart {
                        background-color: var(--theme-color-1);
        
                        &::before,
                        &::after {
                            background-color: var(--theme-color-1);
                        }
                    }
                }

                &:hover {
                    @include desktop {
                        background-color: transparent;
                        border-color: var(--theme-color-badge-5);
                    }

                    & #{$block}-Heart {
                        @include desktop {
                            background-color: var(--theme-color-badge-5);
                        }

                        &::before,
                        &::after {
                            @include desktop {
                                background-color: var(--theme-color-badge-5);
                            }
                        }
                    }
                }
            }
        }
        

        &_isInWishlist {
            &.Button {
                &:not([disabled]) {
                    background-color: var(--theme-color-badge-5);
                    border-color: var(--theme-color-badge-5);

                    &:focus {
                        background-color: var(--theme-color-badge-5);
                        border-color: var(--theme-color-badge-5);

                        @include desktop {
                            background-color: var(--theme-color-badge-5);
                            border-color: var(--theme-color-badge-5);
                        }

                        & #{$block}-Heart {
                            background-color: var(--white);
            
                            &::before,
                            &::after {
                                background-color: var(--white);
                            }
                        }
                    }

                    &:hover {
                        @include before-desktop {
                            background-color: var(--theme-color-badge-5);
                            border-color: var(--theme-color-badge-5);
                        }

                        @include desktop {
                            background-color: var(--theme-color-1);
                            border-color: var(--theme-color-1);
                        }

                        & #{$block}-Heart {
                            @include desktop {
                                background-color: var(--white);
                            }

                            &::before,
                            &::after {
                                @include desktop {
                                    background-color: var(--white);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-Heart {
        top: 2px;
    }
}