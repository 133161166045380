.cms-button {
    width: auto;
    margin: auto;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1rem;
    transition-property: background-color, color, border;
    will-change: background-color, color, border;
    transition-timing-function: ease-out;
    transition-duration: .25s;
    cursor: pointer;
    border-radius: var(--cms-button-border-radius);
    font-weight: var(--cms-button-font-weight);
    font-size: var(--cms-button-font-size);
    line-height: var(--cms-button-line-height);
    padding: var(--cms-button-padding);
    border-width: var(--cms-button-border-width);
    border-style: var(--cms-button-border-style);

    @include mobile {
        font-size: var(--cms-button-font-size-mobile);
        display: block;
    }

    // Button Primary
    color: var(--cms-button-primary-color);
    background-color: var(--cms-button-primary-background-color);
    border-color: var(--cms-button-primary-border-color);

    &:hover,
    &:focus {
        text-decoration: none;

        @include desktop {
            // Button - Primary Hover
            color: var(--cms-button-primary-hover-color);
            background-color: var(--cms-button-primary-hover-background-color);
            border-color: var(--cms-button-primary-hover-border-color);
        }
    }

    &.cms-button-hollow {
        // Button - Primary Hollow
        color: var(--cms-button-primary-hollow-color);
        background-color: var(--cms-button-primary-hollow-background-color);
        border-color: var(--cms-button-primary-hollow-border-color);

        &:hover,
        &:focus {
            @include desktop {
                // Button - Primary Hollow Hover
                color: var(--cms-button-primary-hollow-hover-color);
                background-color: var(--cms-button-primary-hollow-hover-background-color);
                border-color: var(--cms-button-primary-hollow-hover-border-color);
            }
        }
    }

    &.cms-button-secondary {
        // Button - Secondary
        color: var(--cms-button-secondary-color);
        background-color: var(--cms-button-secondary-background-color);
        border-color: var(--cms-button-secondary-border-color);

        &:hover,
        &:focus {
            @include desktop {
                // Button - Secondary Hover
                color: var(--cms-button-secondary-hover-color);
                background-color: var(--cms-button-secondary-hover-background-color);
                border-color: var(--cms-button-secondary-hover-border-color);
            }
        }
    }

    &.cms-button-light-hollow {
        // Button - Secondary
        color: var(--cms-button-light-hollow-color);
        background-color: var(--cms-button-light-hollow-background-color);
        border-color: var(--cms-button-light-hollow-border-color);

        &:hover,
        &:focus {
            @include desktop {
                // Button - Secondary Hover
                color: var(--cms-button-light-hollow-hover-color);
                background-color: var(--cms-button-light-hollow-hover-background-color);
                border-color: var(--cms-button-light-hollow-hover-border-color);
            }
        }
    }

    &.cms-button-white {
        // Button - White
        color: var(--cms-button-white-color);
        background-color: var(--cms-button-white-background-color);
        border-color: var(--cms-button-white-border-color);

        &:hover,
        &:focus {
            @include desktop {
                // Button - White Hover
                color: var(--cms-button-white-hover-color);
                background-color: var(--cms-button-white-hover-background-color);
                border-color: var(--cms-button-white-hover-border-color);
            }
        }
    }

    &.cms-button-white-hollow {
        // Button - White Hollow
        color: var(--cms-button-white-hollow-color);
        background-color: var(--cms-button-white-hollow-background-color);
        border-color: var(--cms-button-white-hollow-border-color);

        &:hover,
        &:focus {
            @include desktop {
                // Button - White Hollow Hover
                color: var(--cms-button-white-hollow-hover-color);
                background-color: var(--cms-button-white-hollow-hover-background-color);
                border-color: var(--cms-button-white-hollow-hover-border-color);
            }
        }
    }

    &.cms-button-black {
        // Button - Black
        color: var(--cms-button-black-color);
        background-color: var(--cms-button-black-background-color);
        border-color: var(--cms-button-black-border-color);

        &:hover,
        &:focus {
            @include desktop {
                // Button - Black Hover
                color: var(--cms-button-black-hover-color);
                background-color: var(--cms-button-black-hover-background-color);
                border-color: var(--cms-button-black-hover-border-color);
            }
        }
    }

    &.cms-button-black-hollow {
        // Button - Black Hollow
        color: var(--cms-button-black-hollow-color);
        background-color: var(--cms-button-black-hollow-background-color);
        border-color: var(--cms-button-black-hollow-border-color);

        &:hover,
        &:focus {
            @include desktop {
                // Button - Black Hollow Hover
                color: var(--cms-button-black-hollow-hover-color);
                background-color: var(--cms-button-black-hollow-hover-background-color);
                border-color: var(--cms-button-black-hollow-hover-border-color);
            }
        }
    }

    + .cms-button {
        margin-left: var(--cms-button-spacing);

        @include mobile {
            margin-left: 0;
            margin-top: var(--cms-button-spacing);
            display: block;
        }
    }


}
