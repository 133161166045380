/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../hoeks-pwa/src/style/abstract/theme_colors';
@import '../../../../hoeks-pwa/src/style/abstract/theme_sizes';

@import '../../../../hoeks-pwa/src/style/abstract/font';
@import '../../../../hoeks-pwa/src/style/abstract/variables';
@import '../../../../hoeks-pwa/src/style/abstract/media';
@import '../../../../hoeks-pwa/src/style/abstract/button';
@import '../../../../hoeks-pwa/src/style/abstract/loader';
@import '../../../../hoeks-pwa/src/style/abstract/icons';
@import '../../../../hoeks-pwa/src/style/abstract/parts';
@import '../../../../hoeks-pwa/src/style/abstract/theme_mixin';

// adjust style for navigation tabs (navigation tabs at the top)
body {
    // reset height for sticky dependent elements
    --navigation-tabs-height: 0;

    .NavigationTabs {
        // set height for the main element 
        --navigation-tabs-height: 50px;

        &-Button {
            flex-grow: 0;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }

        // style for logo
        &-LogoBtn {
            flex-grow: 1;
            justify-content: flex-start;
            margin-right: 2rem;
            display: block;
        }

        &-LogoWrapper {
            height: auto;
            width: auto;
            padding: 0;
            max-height: 100%;
            display: block;
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
        }

        &-LogoImg {
            display: block;
            max-height: var(--header-nav-height);
            max-width: 150px;
            width: auto ;
            padding: 5px 0;
        }

        // end style for logo

    }
}