/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../style/abstract/theme_colors';
@import '../../../style/abstract/theme_sizes';

@import '../../../style/abstract/font';
@import '../../../style/abstract/variables';
@import '../../../style/abstract/media';
@import '../../../style/abstract/button';
@import '../../../style/abstract/loader';
@import '../../../style/abstract/icons';
@import '../../../style/abstract/parts';
@import '../../../style/abstract/theme_mixin';

.BlogPostCard {
    $block: &;
    
    & .Image-Image {
        @include desktop {
            transform: scale(1);
            transition: ease-in all .2s;
        }
    }
    
    &:hover {
        .Image-Image {
            @include desktop {
                transform: scale(1.05);
                transition: ease-in all .2s;
            }
        }
    }

    &.BlogPostCard_isFeatured {
        grid-column: span 2;

        @include phone {
            grid-column: span 1;
        }
    }

    &:nth-child(11n+3) {
        @include desktop {
            grid-column: span 2;
        }
    }

    &:nth-child(11n+4) {
        @include desktop {
            grid-column: span 2;
        }
    }

    &:nth-child(11n+5) {
        @include desktop {
            grid-column: span 2;
            grid-row: span 2;
        }
    }

    &:nth-child(11n+8) {
        @include desktop {
            grid-column: span 2;
        }
    }

    &-ContentWrapper {
        height: 100%;
    }

    &-InnerWrapper {
        height: 100%;
        background: #fff;
    }

    &-ImageWrapper {
        display: block;
        width: 100%;
        height: 100%;
    }

    &-Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
        padding-bottom: 0;

        & .Image-Image {
            width: calc(100% + 2px) !important;
            height: calc(100% + 2px) !important;
            object-fit: cover !important;
            top: -1px;
            left: -1px;

            @include desktop {
                transition: ease-in all .2s;
            }

            &::after {
                background-color: rgba(0, 0, 0, 0);
            }
        }
    }

    &-Details {
        position: absolute;
        bottom: 0;
        background-color: #fff;
        width: 100%;
        min-height: 78px;

        & a {
            color: var(--black);

            &:hover {
                text-decoration: none;
            }
        }
    }

    &-TextTitle {
        margin-top: var(--theme-spacing-8);
        margin-bottom: 0;
        @include font-heading4;

        @include desktop {
            max-width: 325px;
        }
    }

    &-DescriptionWrapper {
        max-height: 1000px;
    }
}
