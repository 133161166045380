.isLoginPageB2B {
    min-height: 100vh;
    background-color: var(--theme-color-1);

    &::after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url(https://berrybean.dk/media/teaser-page/FieldPattern.svg);
        background-size: contain;
        background-position: 0 115%;
        background-repeat: no-repeat;
        pointer-events: none;
        opacity: .3;
        z-index: -1;

        @include mobile {
            background-size: cover;
            background-position: center 100px;
        }
    }

    .ContentWrapper  {
        height: 100vh;
    }

    .Header-Wrapper {
        display: none;
    }
    .Footer {
        display: none;
    }
}