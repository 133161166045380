/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../../../src/style/abstract/theme_colors';
@import '../../../../../../src/style/abstract/theme_sizes';

@import '../../../../../../src/style/abstract/font';
@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/theme_mixin';

.PriceSlider {

    &-Main {
        // custom properties to quickly change the looks 
        --track-color: #d0d0d0;
        --track-height: 4px;
        --thumb-color: #394831;
        --slider-height: 2.6rem;
        // end custom properties 
        margin-bottom: 4.8rem;

        @include mobile {
            background-color: #fff;
            padding: .7rem 2.4rem;
            padding-bottom: 4.8rem;
        }

    }

    &-TitleContainer {
        span {
            @include mobile {
                text-transform: none;
            }
        }
    }

    &-SliderContainer {

        padding: 2.4rem 2.4rem 0;
        padding-top: 0;
        margin-top: 2.4rem;
        display: flex;
        justify-content: center;
        height: var(--slider-height);
        &:after {
            content: "";
            display: block;
            width: calc(100% - 4.8rem);
            height: var(--track-height);
            background-color: var(--track-color);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
        input {
            display: block;
            background-color: transparent;
            margin: 0;
            border: 0;
            padding: 0;
            width: calc(100% - 4.8rem)!important;
            left: 50%;
            transform: translateX(-50%);
            min-height: 0;
            z-index: 2;
            pointer-events: none;
            position: absolute;
            overflow: hidden;
            outline: none;
            height: var(--slider-height);
            &:not(:first-of-type) {
                margin-top: 0;
            }
            &:focus {
                outline: none;
                border: 0;
            }
            &::-webkit-slider-thumb {
                pointer-events: all;
                position: relative;
                z-index: 1;
                outline: 0;
                -webkit-appearance: none;
                width: var(--slider-height);
                height: var(--slider-height);
                border-radius: 50%;
                background-color: var(--thumb-color);
                overflow: visible;
                cursor: pointer;
            }
            &::-moz-range-thumb {
                pointer-events: all;
                position: relative;
                z-index: 1;
                outline: 0;
                -webkit-appearance: none;
                width: var(--slider-height);
                height: var(--slider-height);
                border-radius: 50%;
                background-color: var(--thumb-color);
                overflow: visible;
                cursor: pointer;
            }
            &::-moz-focus-outer {
                border: 0;
            }
            &.PriceSlider-MaxSlider_isTop {
                z-index: 3;
            }
            &.PriceSlider-MinSlider_isTop {
                z-index: 3;
            }
        }
    }

    &-TextContainer {
        padding: 2.4rem 2.4rem 0;
        display: flex;
        justify-content: center;
        span {
            display: block;
            font-size: 1.6rem;
        }
    }

    &-ValSeparator {
        margin: 0 .6rem;
    }

}