/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --input-type-number-width: 80px;
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderAnimation 2s linear infinite;
    --primary-divider-color: #f0f0f0;
    --secondary-error-color: #{$white};
    --primary-error-color: #{$theme-color-badge-5};
    --secondary-success-color: #{$white};
    --primary-success-color: #{$theme-color-1};
    --secondary-info-color: #{$white};
    --primary-info-color: #{$theme-color-badge-3};
    --body-content-color: #{$black};

    // Declare imported primary colors to change the theme's colors
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --primary-base-color: var(--imported_primary_base_color, #{$default-primary-base-color});
    --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});
    --secondary-base-color: var(--imported_secondary_base_color, #{$default-secondary-base-color});
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});

    // Extra color variable defines
    --white: #{$white};
    --black: #{$black};

    // Berry & Bean button
    --button-radius: #{$theme-button-radius};

    // Berry & Bean color Scheme
    --theme-color-1: #{$theme-color-1};
    --theme-color-1-filter: #{$theme-color-1-filter};
    --theme-color-2: #{$theme-color-2};
    --theme-color-3: #{$theme-color-3};
    --theme-color-4: #{$theme-color-4};
    --theme-color-5: #{$theme-color-5};
    --theme-color-6: #{$theme-color-6};
    --theme-color-7: #{$theme-color-7};
    --theme-color-8: #{$theme-color-8};
    --theme-color-9: #{$theme-color-9};
    --theme-color-10: #{$theme-color-10};
    --theme-color-10a: #{$theme-color-10a};
    --theme-color-10b: #{$theme-color-10b};
    --theme-color-10c: #{$theme-color-10c};
    --theme-color-10d: #{$theme-color-10d};
    --theme-color-10e: #{$theme-color-10e};
    --theme-color-11: #{$theme-color-11};
    --theme-color-12: #{$theme-color-12};

    --theme-color-copyright: #{$theme-color-copyright};

    --theme-color-badge-1: #{$theme-color-badge-1};
    --theme-color-badge-2: #{$theme-color-badge-2};
    --theme-color-badge-3: #{$theme-color-badge-3};
    --theme-color-badge-4: #{$theme-color-badge-4};
    --theme-color-badge-5: #{$theme-color-badge-5};

    --theme-color-product-1: #{$theme-color-product-1};
    --theme-color-product-2: #{$theme-color-product-2};

    --theme-color-overlay: #{$theme-color-overlay};

    // Berry & Bean spacing scheme
    --theme-spacing-8: #{$theme-spacing-8};
    --theme-spacing-16: #{$theme-spacing-16};
    --theme-spacing-20: #{$theme-spacing-20};
    --theme-spacing-24: #{$theme-spacing-24};
    --theme-spacing-32: #{$theme-spacing-32};
    --theme-spacing-48: #{$theme-spacing-48};
    --theme-spacing-64: #{$theme-spacing-64};
    --theme-spacing-72: #{$theme-spacing-72};
    --theme-spacing-96: #{$theme-spacing-96};
    --theme-spacing-128: #{$theme-spacing-128};

    // Berry & Bean font family scheme
    --theme-font-family-primary: #{$theme-font-family-primary};
    --theme-font-family-secondary: #{$theme-font-family-secondary};
    --theme-font-family-display: #{$theme-font-family-display};

    // Berry & Bean font size scheme
    --theme-font-size: #{$theme-font-size};
    --theme-font-size-button: #{$theme-font-size-button};
    --theme-font-size-p: #{$theme-font-size-p};
    --theme-font-size-topmenu-p: #{$theme-font-size-topmenu-p};
    --theme-font-size-menu: #{$theme-font-size-menu};
    --theme-font-size-menu-mobile: #{$theme-font-size-menu-mobile};
    --theme-font-size-menu2: #{$theme-font-size-menu2};
    --theme-font-size-menu2-mobile: #{$theme-font-size-menu2-mobile};
    --theme-font-size-menu2-p: #{$theme-font-size-menu2-p};
    --theme-font-size-menu-search: #{$theme-font-size-menu-search};
    --theme-font-size-hero-p: #{$theme-font-size-hero-p};
    --theme-font-size-hero-h: #{$theme-font-size-hero-h};
    --theme-font-size-footer-p: #{$theme-font-size-footer-p};
    --theme-font-size-footer-p-mobile: #{$theme-font-size-footer-p-mobile};
    --theme-font-size-footer-h: #{$theme-font-size-footer-h};
    --theme-font-size-footer-h-mobile: #{$theme-font-size-footer-h-mobile};
    --theme-font-size-cms-button: #{$theme-font-size-cms-button};
    --theme-font-size-cms-p: #{$theme-font-size-cms-p};
    --theme-font-size-cms-h1: #{$theme-font-size-cms-h1};
    --theme-font-size-cms-h-inside: #{$theme-font-size-cms-h-inside};
    --theme-font-size-cms-h-inside2: #{$theme-font-size-cms-h-inside2};
    --theme-font-size-cms-subtext1: #{$theme-font-size-cms-subtext1};
    --theme-font-size-product: #{$theme-font-size-product};
    --theme-font-size-expandable-content-h: #{$theme-font-size-expandable-content-h};
    --theme-font-size-expandable-content-p: #{$theme-font-size-expandable-content-p};

    // Berry & Bean font weight scheme
    --theme-font-weight-normal: #{$theme-font-weight-normal};
    --theme-font-weight-medium: #{$theme-font-weight-medium};
    --theme-font-weight-semibold: #{$theme-font-weight-semibold};
    --theme-font-weight-bold: #{$theme-font-weight-bold};
    --theme-font-weight-button: #{$theme-font-weight-button};
    --theme-font-weight-topmenu-p: #{$theme-font-weight-topmenu-p};
    --theme-font-weight-menu: #{$theme-font-weight-menu};
    --theme-font-weight-menu2: #{$theme-font-weight-menu2};
    --theme-font-weight-menu2-p: #{$theme-font-weight-menu2-p};
    --theme-font-weight-menu-search: #{theme-font-weight-menu-search};
    --theme-font-weight-menu-mobile: #{$theme-font-weight-menu-mobile};
    --theme-font-weight-menu2-mobile: #{$theme-font-weight-menu2-mobile};
    --theme-font-weight-hero-p: #{$theme-font-weight-hero-p};
    --theme-font-weight-hero-h: #{$theme-font-weight-hero-h};
    --theme-font-weight-product: #{$theme-font-weight-product};
    --theme-font-weight-product2: #{$theme-font-weight-product2};
    --theme-font-weight-expandable-content-h: #{$theme-font-weight-expandable-content-h};
    --theme-font-weight-expandable-content-p: #{$theme-font-weight-expandable-content-p};

    // Berry & Bean extras
    --theme-link-underline-offset: #{$theme-link-underline-offset};
}
