/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../style/abstract/theme_colors';
@import '../../../../style/abstract/theme_sizes';

@import '../../../../style/abstract/font';
@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
@import '../../../../style/abstract/theme_mixin';

.ProductAlert {
    $block: &;

    width: 100%;

    &-Fields {
        & .Field {
            #{$block} & {
                margin-top: 0;
            }

            & input {
                width: 100%;
            }
        }
    }

    & .Button {
        width: 100%;
        margin-top: var(--theme-spacing-16);
    }
}