@mixin font-heading-extralarge {
  font-family: var(--theme-font-family-secondary);
  font-weight: 400;
  color: var(--black);
  
  font-size: 10rem;
  line-height: 10rem;

  @include before-desktop {
    font-size: 3.8rem; // 5.7rem
    line-height: 4.3rem; // 6.5rem
  }
}

@mixin font-heading1 {
  font-family: var(--theme-font-family-secondary);
  font-weight: 400;
  color: var(--black);
  
  font-size: 6.3rem;
  line-height: 7.0rem;

  @include before-desktop {
    font-size: 3.8rem; // 5.7rem
    line-height: 4.3rem; // 6.5rem
  }
}

@mixin font-heading2 {
  font-family: var(--theme-font-family-primary);
  font-weight: 600;
  color: var(--black);
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  
  font-size: 3.0rem;
  line-height: 4.0rem;

  @include before-desktop {
    font-size: 2.5rem; // 3.8rem
    line-height: 2.8rem; // 4.2rem
  }
}

@mixin font-heading3 {
  font-family: var(--theme-font-family-secondary);
  font-weight: 400;
  color: var(--black);
  
  font-size: 3.5rem;
  line-height: 4.4rem;

  @include before-desktop {
    font-size: 2.9rem; // 4.4rem
    line-height: 2.9rem; // 4.4rem
  }
}

@mixin font-heading4 {
  font-family: var(--theme-font-family-secondary);
  font-weight: 400;
  color: var(--black);
  
  font-size: 2.4rem;
  line-height: 3.1rem;

  @include before-desktop {
    //font-size: 3.5rem;
    line-height: 2.7rem; // 4.0rem
  }
}

@mixin font-heading5 {
  font-family: var(--theme-font-family-primary);
  font-weight: 500;
  color: var(--black);
  
  font-size: 2.9rem;
  line-height: 3.4rem;

  @include before-desktop {
    font-size: 1.8rem; // 2.7rem
    line-height: 2.4rem; // 3.5rem
  }
}

@mixin font-heading6 {
  font-family: var(--theme-font-family-primary);
  font-weight: 600;
  color: var(--black);
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  
  font-size: 1.4rem;
  line-height: 2.4rem;

  @include before-desktop {
    font-size: 1.6rem; // 2.4rem
    line-height: 2.1rem; //3.2rem
  }
}

@mixin font-heading-checkout {
  font-family: var(--theme-font-family-primary);
  font-weight: 600;
  color: var(--black);
  letter-spacing: 0.16rem;
  text-transform: uppercase;
  font-size: 2.4rem;
  line-height: 2.4rem;

  @include before-desktop {
    font-size: 1.6rem; // 2.4rem
    line-height: 2.1rem; //3.2rem
  }
}

@mixin font-paragraph {
  font-family: var(--theme-font-family-primary);
  font-weight: 500;
  color: var(--black);
  
  font-size: 1.7rem;
  line-height: 1.6;
  // line-height: 2.4rem;

  // @include before-desktop {
  //   font-size: 2.7rem;
  //   line-height: 3.5rem;
  // }
}

@mixin font-tab-heading {
  font-family: var(--theme-font-family-primary);
  font-weight: 600;
  color: var(--black);
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}

@mixin font-topmenu {
  font-family: var(--theme-font-family-primary);
  font-weight: 700;
  letter-spacing: 0.2rem;
  color: var(--white);
  text-transform: uppercase;
  
  font-size: 1.3rem;

  // @include before-desktop {
  //   font-size: 1.9rem;
  // }
}

@mixin font-menu-search {
  font-family: var(--theme-font-family-primary);
  font-weight: 500;
  color: var(--theme-color-9);
  
  font-size: 1.7rem;

  // @include before-desktop {
  //   font-size: 2.7rem;
  // }
}

@mixin font-menu1 {
  font-family: var(--theme-font-family-primary);
  font-weight: 600;
  color: var(--black);
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  
  font-size: 1.6rem;

  // @include before-desktop {
  //   font-size: 2.4rem;
  // }
  @include before-desktop {
    font-family: var(--theme-font-family-secondary);
    font-weight: 400;
    letter-spacing: normal;
    text-transform: none;
    font-size: 2.9rem; // 4.4rem
  }
}

@mixin font-menu2 {
  color: var(--black);

  font-family: var(--theme-font-family-primary);
  font-weight: 600;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  font-size: 1.6rem;

  // @include before-desktop {
  //   font-family: var(--theme-font-family-secondary);
  //   font-weight: 400;
  //   letter-spacing: normal;
  //   text-transform: none;
  //   font-size: 2.9rem; // 4.4rem
  // }
  @include before-desktop {
    font-size: 1.9rem;
  }
}

@mixin font-menu3 {
  font-family: var(--theme-font-family-primary);
  font-weight: 500;
  color: var(--black);
  
  font-size: 1.9rem;

  // @include before-desktop {
  //   font-size: 2.7rem;
  // }
}

@mixin font-menu3-image {
  @include desktop {
    font-family: var(--theme-font-family-secondary);
    font-weight: 400;
    color: var(--black);
    
    font-size: 2.2rem;
  }
}

@mixin font-footer-heading {
  font-family: var(--theme-font-family-secondary);
  font-weight: 400;
  color: var(--white);
  
  font-size: 2.4rem;
  line-height: 3.1rem;

  @include before-desktop {
    //font-size: 3.5rem;
    line-height: 2.7rem; // 4.0rem
  }
}

@mixin font-footer {
  font-family: var(--theme-font-family-primary);
  font-weight: 500;
  color: var(--white);
  
  font-size: 1.7rem;

  @include before-desktop {
    font-size: 1.5rem; // 2.2rem
  }
}

@mixin font-footer-copyright {
  font-family: var(--theme-font-family-primary);
  font-weight: 500;
  color: var(--theme-color-copyright);
  
  font-size: 1.4rem;

  @include before-desktop {
    font-size: 1.2rem; // 1.7rem
  }
}

@mixin font-product1 {
  font-family: var(--theme-font-family-primary);
  font-weight: 500;
  color: var(--black);
  
  font-size: 1.7rem;

  @include before-desktop {
    font-size: 1.5rem; // 2.2rem
  }
}

@mixin font-product2 {
  font-family: var(--theme-font-family-primary);
  font-weight: 600;
  color: var(--black);
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  
  font-size: 1.4rem;

  @include before-desktop {
    font-size: 1.2rem; // 1.7rem
  }
}

@mixin font-product-price {
  font-family: var(--theme-font-family-primary);
  font-weight: 600;
  color: var(--black);
  text-transform: uppercase;
  
  font-size: 1.7rem;

  @include before-desktop {
    font-size: 1.5rem; // 2.2rem
  }
}

@mixin font-product-p {
  font-family: var(--theme-font-family-secondary);
  font-weight: 400;
  color: var(--black);
  
  font-size: 1.8rem;

  @include before-desktop {
    font-size: 1.8rem; 
  }
}

@mixin font-product-p2 {
  font-family: var(--theme-font-family-primary);
  font-weight: 500;
  color: var(--black);
  
  font-size: 1.9rem;

  @include before-desktop {
    font-size: 1.9rem; 
  }
}

@mixin font-button {
  font-family: var(--theme-font-family-primary);
  font-weight: 600;
  color: var(--white);
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  
  font-size: 1.4rem;

  @include before-desktop {
    font-size: 1.6rem; // 2.4rem
  }
}

@mixin font-button-filter {
  font-family: var(--theme-font-family-primary);
  font-weight: 600;
  color: var(--black);
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  
  font-size: 1.4rem;

  // @include before-desktop {
  //   font-size: 2.0rem;
  // }
}

@mixin font-button-social {
  font-family: var(--theme-font-family-primary);
  font-weight: 600;
  color: var(--black);
  text-transform: uppercase;
  
  font-size: 1.9rem;

  @include before-desktop {
    font-size: 1.5rem; // 2.2rem
  }
}

@mixin image-reset {
  .Image {
    &:not(.Logo) {
      padding: 0;
      height: auto;
      display: block;
      width: auto;

      .Image-Image {
        position: relative;
        height: auto !important;
        object-fit: contain;
        display: block;
        width: auto !important;
      }
    }
  }
}
