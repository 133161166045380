/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../../../src/style/abstract/theme_colors';
@import '../../../../../../src/style/abstract/theme_sizes';

@import '../../../../../../src/style/abstract/font';
@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/theme_mixin';

.SubscriptionCartItem {
    .Details-List {
        font-size: 1rem;
        text-transform: uppercase;
        color: var(--cart-item-option-color);
        padding: 3px 5px;
        margin: 5px 7px 0 0;

        @include mobile {
            margin-bottom: .7rem;
        }
    }
}

.CartPage {
    .SubscriptionCartItem {
        .Details-List {
            @include desktop {
                max-width: 40%;
            }
        }
    }
}