/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../style/abstract/theme_colors';
@import '../../style/abstract/theme_sizes';

@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme_mixin';

.Breadcrumbs {
    @include desktop {
        display: none;
    }

	.isAccountPage & {
		@include desktop {
			display: block;
			background-color: var(--white);
		}
	}

    .isCategoryPage & {
		@include desktop {
			display: block;
			background-color: var(--white);
		}

        &-List {
            padding: 24px 0;
        }
	}

    .isProductPage & {
        @include desktop {
            display: block;
            background-color: transparent;
            position: absolute;
            max-width: var(--content-wrapper-width);
            width: 100%;
            margin: 0 auto;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin-top: 2.4rem;
        }

        & .ContentWrapper {
            @include desktop {
                max-width: var(--content-wrapper-width);
                z-index: 1;
            }
        }

        & nav {
            @include desktop {
                // position: absolute;
                // top: 85px;
            }
        }
    }

    &-List {
		.isAccountPage & {
			padding: var(--theme-spacing-32) 0;
		}

        .isProductPage & {
            padding-top: 0;
        }
    }

	& .ContentWrapper {
		@include desktop {
			padding: 0 var(--theme-spacing-24);
		}
	}
}