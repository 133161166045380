/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../style/abstract/theme_colors';
@import '../../style/abstract/theme_sizes';

@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme_mixin';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --loader-scale: 1.2;
}

.Loader {
    background-color: rgba(255, 255, 255, .6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 75;
    margin: auto;
    opacity: 0;
    animation: appear 1ms 200ms forwards;

    &-Main {
        span {
            display: block;
            height: 40px;
            width: 40px;
            background-image: url(icons/bean.png);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            animation: spin 2s linear infinite;
        }
    }

    &-Scale {
        position: absolute;
        transform: translate(-50%, -50%) scale(var(--loader-scale));
        left: 50%;
        top: calc(50% - (3px * var(--loader-scale)));
    }
}

@keyframes MoveUpDown {
    0% {
        top: 0;
    }

    25% {
        top: 25px;
    }

    50% {
        top: 0;
    }

    75% {
        top: -25px;
    }

    100% {
        top: 0;
    }
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
