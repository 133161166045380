/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../../../src/style/abstract/theme_colors';
@import '../../../../../../src/style/abstract/theme_sizes';

@import '../../../../../../src/style/abstract/font';
@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/theme_mixin';

.MyAccountMySubscriptionProfiles-Table {
    width: 100%;

    thead {
        @include mobile {
            display: none;
        }
    }

    td[data-th] {
        @include mobile {
            display: block;
        }
    }

    td[data-th]:before {
        @include mobile {
            padding-right: 10px;
            content: attr(data-th) ': ';
            display: inline-block;
            color: #111;
            font-weight: 700;
        }
    }
}