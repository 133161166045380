.cms-appetizer {
    .cms-col & {
        font-family: var(--theme-font-family-primary);
        font-weight: 500;
        text-align: center;
        
        font-size: 2.2rem;
        line-height: 3.0rem;

        @include before-desktop {
            font-size: 1.8rem;
            line-height: 2.4rem;
        }
    }
}

.cms-header-underlay {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 1200px;
    z-index: -1;
    overflow-x: clip;
}

.cms-header-image {
    .PostsDetails-Content & {
        &:not(.Logo) {
            & .Image {
                &-Image {
                    width: 100% !important;
                }
            }
        }
    }
}

.cms-header-underlay-image, .cms-bg-third {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150vw;
    height: 35%;
    z-index: -1;
    overflow-x: clip;
}

.cms-bg-half {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150vw;
    height: 50%;
    z-index: -1;
    overflow-x: clip;
}

.cms-bg-two-thirds {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150vw;
    height: 65%;
    z-index: -1;
    overflow-x: clip;
}

.cms-grid {
    display: grid;
    width: 100%;

    &.grid-col-1 { grid-template-columns: 1fr; }
    &.grid-col-2 { grid-template-columns: repeat(2, 1fr); }
    &.grid-col-3 { grid-template-columns: repeat(3, 1fr); }
    &.grid-col-4 { grid-template-columns: repeat(4, 1fr); }
    &.grid-col-5 { grid-template-columns: repeat(5, 1fr); }
    &.grid-col-6 { grid-template-columns: repeat(6, 1fr); }

    @include desktop {
        &.grid-col-1-lg { grid-template-columns: 1fr; }
        &.grid-col-2-lg { grid-template-columns: repeat(2, 1fr); }
        &.grid-col-3-lg { grid-template-columns: repeat(3, 1fr); }
        &.grid-col-4-lg { grid-template-columns: repeat(4, 1fr); }
        &.grid-col-5-lg { grid-template-columns: repeat(5, 1fr); }
        &.grid-col-6-lg { grid-template-columns: repeat(6, 1fr); }
    }

    @include before-desktop {
        &.grid-col-1-bd { grid-template-columns: 1fr; }
        &.grid-col-2-bd { grid-template-columns: repeat(2, 1fr); }
        &.grid-col-3-bd { grid-template-columns: repeat(3, 1fr); }
        &.grid-col-4-bd { grid-template-columns: repeat(4, 1fr); }
        &.grid-col-5-bd { grid-template-columns: repeat(5, 1fr); }
        &.grid-col-6-bd { grid-template-columns: repeat(6, 1fr); }
    }

    @include tablet {
        &.grid-col-1-md { grid-template-columns: 1fr; }
        &.grid-col-2-md { grid-template-columns: repeat(2, 1fr); }
        &.grid-col-3-md { grid-template-columns: repeat(3, 1fr); }
        &.grid-col-4-md { grid-template-columns: repeat(4, 1fr); }
        &.grid-col-5-md { grid-template-columns: repeat(5, 1fr); }
        &.grid-col-6-md { grid-template-columns: repeat(6, 1fr); }
    }

    @include phone {
        &.grid-col-1-sm { grid-template-columns: 1fr; }
        &.grid-col-2-sm { grid-template-columns: repeat(2, 1fr); }
        &.grid-col-3-sm { grid-template-columns: repeat(3, 1fr); }
        &.grid-col-4-sm { grid-template-columns: repeat(4, 1fr); }
        &.grid-col-5-sm { grid-template-columns: repeat(5, 1fr); }
        &.grid-col-6-sm { grid-template-columns: repeat(6, 1fr); }
    }
}

.cms-flex {
    display: flex;
    flex-wrap: wrap;

    &:not(.direction-col) {

        &.gap-single {
            & > * { margin: 0 calc(var(--cms-spacing-x1) / 2); }
        }

        &.gap-single-sm {
            @include phone {
                & > * { margin: 0 calc(var(--cms-spacing-x1) / 2); }
            }
        }

        &.gap-single-md {
            @include tablet {
                & > * { margin: 0 calc(var(--cms-spacing-x1) / 2); }
            }
        }

        &.gap-single-bd {
            @include before-desktop {
                & > * { margin: 0 calc(var(--cms-spacing-x1) / 2); }
            }
        }

        &.gap-single-lg {
            @include desktop {
                & > * { margin: 0 calc(var(--cms-spacing-x1) / 2); }
            }
        }

        &.gap-double {
            & > * { margin: 0 calc(var(--cms-spacing-x2) / 2); }
        }

        &.gap-double-sm {
            @include phone {
                & > * { margin: 0 calc(var(--cms-spacing-x2) / 2); }
            }
        }

        &.gap-double-md {
            @include tablet {
                & > * { margin: 0 calc(var(--cms-spacing-x2) / 2); }
            }
        }

        &.gap-double-bd {
            @include before-desktop {
                & > * { margin: 0 calc(var(--cms-spacing-x2) / 2); }
            }
        }

        &.gap-double-lg {
            @include desktop {
                & > * { margin: 0 calc(var(--cms-spacing-x2) / 2); }
            }
        }

        &.gap-triple {
            & > * { margin: 0 calc(var(--cms-spacing-x3) / 2); }
        }

        &.gap-triple-sm {
            @include phone {
                & > * { margin: 0 calc(var(--cms-spacing-x3) / 2); }
            }
        }

        &.gap-triple-md {
            @include tablet {
                & > * { margin: 0 calc(var(--cms-spacing-x3) / 2); }
            }
        }

        &.gap-triple-bd {
            @include before-desktop {
                & > * { margin: 0 calc(var(--cms-spacing-x3) / 2); }
            }
        }

        &.gap-triple-lg {
            @include desktop {
                & > * { margin: 0 calc(var(--cms-spacing-x3) / 2); }
            }
        }
    }

    &.direction-col {
        flex-direction: column;

        &.gap-single {
            & > * { margin: calc(var(--cms-spacing-x1) / 2) 0; }
        }

        &.gap-single-sm {
            @include phone {
                & > * { margin: calc(var(--cms-spacing-x1) / 2) 0; }
            }
        }

        &.gap-single-md {
            @include tablet {
                & > * { margin: calc(var(--cms-spacing-x1) / 2) 0; }
            }
        }

        &.gap-single-bd {
            @include before-desktop {
                & > * { margin: calc(var(--cms-spacing-x1) / 2) 0; }
            }
        }

        &.gap-single-lg {
            @include desktop {
                & > * { margin: calc(var(--cms-spacing-x1) / 2) 0; }
            }
        }

        &.gap-double {
            & > * { margin: calc(var(--cms-spacing-x2) / 2) 0; }
        }

        &.gap-double-sm {
            @include phone {
                & > * { margin: calc(var(--cms-spacing-x2) / 2) 0; }
            }
        }

        &.gap-double-md {
            @include tablet {
                & > * { margin: calc(var(--cms-spacing-x2) / 2) 0; }
            }
        }

        &.gap-double-bd {
            @include before-desktop {
                & > * { margin: calc(var(--cms-spacing-x2) / 2) 0; }
            }
        }

        &.gap-double-lg {
            @include desktop {
                & > * { margin: calc(var(--cms-spacing-x2) / 2) 0; }
            }
        }

        &.gap-triple {
            & > * { margin: calc(var(--cms-spacing-x3) / 2) 0; }
        }

        &.gap-triple-sm {
            @include phone {
                & > * { margin: calc(var(--cms-spacing-x3) / 2) 0; }
            }
        }

        &.gap-triple-md {
            @include tablet {
                & > * { margin: calc(var(--cms-spacing-x3) / 2) 0; }
            }
        }

        &.gap-triple-bd {
            @include before-desktop {
                & > * { margin: calc(var(--cms-spacing-x3) / 2) 0; }
            }
        }

        &.gap-triple-lg {
            @include desktop {
                & > * { margin: calc(var(--cms-spacing-x3) / 2) 0; }
            }
        }
    }
    
    &.align-center {
        align-items: center;
        align-content: center;
    }

    &.justify-center {
        justify-content: center;
        justify-items: center;
    }
}

.cms-socialmedia {
    & .CMSMenuOverlaySocialMedia {
        @include before-desktop {
            justify-content: center;
        }
    }

    & .CMSMenuOverlaySocialMedia-Link {
        @include desktop {
            padding: var(--theme-spacing-24);
        }
    }

    & .CMSMenuOverlaySocialMedia-Icon {
        @include desktop {
            width: var(--theme-spacing-24);
            height: var(--theme-spacing-24);
        } 
    }
}
