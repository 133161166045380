.CMSFeatureKaffe {
    display: grid;
    grid-template-columns: auto;
    position: relative;
    overflow: clip;

    @include after-phone {
        grid-template-columns: repeat(2, 1fr);
    }

    &-Card {
        height: 35rem;
        background-color: var(--theme-color-1);

        @include desktop {
            height: 90rem;
        }
    }

    &-Link {
        display: block;
        width: 100%;
        height: 100%;

        & .Button {
            width: auto;
            max-width: calc(100% - 48px);
        }
    }

    &-Image {
        &.Image:not(.Logo) {
            height: 100%;
            width: 100%;
    
            &.Image {
                & .Image-Image {
                    width: 100% !important;
                    height: 100% !important;
                    object-fit: cover;
                }
            }
        }

        padding-bottom: unset;
        height: 100%;
        width: 100%;

        & .Image-Image {
            object-fit: cover;
        }
    }

    &-Text {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 40px));
        width: fit-content;
        padding: 0 var(--theme-spacing-24);
        text-align: center;

        @include font-heading3;
        letter-spacing: 0.1rem;
        color: var(--white);
    }

    &-Button {
        &.Button {
        display: block;
        position: absolute;
        top: -50%;
        left: 50%;
        transform: translate(-50%, calc(-50% + 40px));

        color: var(--white);
        border-color: var(--white);

        &:hover {
            @include desktop {
                border-color: var(--white);
                background-color: var(--white);
                color: var(--black);
            }
        }
    }   
    }
}