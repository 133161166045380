/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// NOTE(Bjarne): Berry&Bean extra theme scss variables
@import '../../../../../../src/style/abstract/theme_colors';
@import '../../../../../../src/style/abstract/theme_sizes';

@import '../../../../../../src/style/abstract/font';
@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/theme_mixin';

.CommentField {
    margin-bottom: 2.4rem;

    &-Heading {
        text-transform: none;
        font-weight: 400;
        font-size: 2rem;
        margin: 0 0 1.2rem;
    }

    &-Inputs {
        @include desktop {
            display: flex;
            margin: 0 -1.5rem;
        }

        & > * {
            @include desktop {
                padding: 0 1.5rem;
                margin-top: 0;
            }
        }
    }

    .Field_name_shipping_comment {
        input {
            display: block;
            width: 100%;
            margin-top: 1rem;
            // min-height: 100px;
        }
    }

    .Field_name_shipping_flex_accept {
        margin-bottom: 2rem;
        label {
            padding-left: 3rem;
            .input-control {
                position: absolute;
                left: 0;
                margin-left: 0;
                top: 0;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

}